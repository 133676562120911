import { Leader } from "../store";


export const getLeadersForScheduling = (leaders: Leader[]) => leaders.filter(leader => 
  !leader.is_shared
  || (leader.is_shared && leader.permissions.scheduling
    && Object.values(leader.permissions.scheduling).some((perm: boolean) => perm)
  )
);

export const getLeaderIconSrc = (leader: Pick<Partial<Leader>, 'pic_url'>) => (
  leader.pic_url && leader.pic_url.split('/').pop()?.startsWith('default_')
    ? undefined
    : leader.pic_url
);
